import axios from "axios";

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_API_REACT || "http://localhost:8000/hinzu-django/api-react"
// !REMEMBER! to check hardcoded urls

export default axios.create({
  baseURL: BACKEND_API_URL,
  headers: {
    "Content-type": "application/json"
  },
  withCredentials: true,
});
