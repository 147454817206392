// Currently you should hardcode in index.html initiatian, but it is better to do
// via https://blog.cetindere.de/integrate-google-analytics-into-your-react-web-app/
// Note: that all this custom event should be registered in google analytics

export const registerUploadButtonPushed = () => {
    window.gtag('event', 'upload_button_pushed');
};

export const registerChooseFileToUpload = () => {
    window.gtag('event', 'file_to_upload_chosed');
};

export const registerResultSeen = () => {
    window.gtag('event', 'result_seen');
};

export const registerVotePressed = (value) => {
    window.gtag('event', 'vote_pressed', {value: value});
};

export default {
    registerUploadButtonPushed,
    registerChooseFileToUpload,
};
